/**
 * @generated SignedSource<<35a412caee57f01381d5b901abecfe90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationChannel = "email" | "slack" | "in_app" | "mobile" | "%future added value";
export type NotificationKindSlug = "assignment_submitted" | "new_registration" | "curriculum_module_released" | "comment_reply_chat_mention" | "upcoming_course_event" | "new_direct_message" | "event_share" | "occurrence_capacity_reached" | "post_share" | "post_published" | "new_community_member" | "content_flagged" | "event_published" | "curriculum_due_dates" | "curriculum_item_share" | "quiz_submitted" | "survey_submitted" | "new_bot_suggestion" | "new_guest_registration" | "new_pathway_registration" | "ai_generation_end" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationKindListItemFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly slug: NotificationKindSlug;
  readonly channels: ReadonlyArray<NotificationChannel>;
  readonly viewerOptOut: {
    readonly id: string;
    readonly channels: ReadonlyArray<{
      readonly channel: NotificationChannel;
      readonly optedOut: boolean;
    }>;
  } | null;
  readonly " $fragmentType": "NotificationKindListItemFragment";
};
export type NotificationKindListItemFragment$key = {
  readonly " $data"?: NotificationKindListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationKindListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationKindListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "channels",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "concreteType": "NotificationOptOut",
      "kind": "LinkedField",
      "name": "viewerOptOut",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationOptOutChannel",
          "kind": "LinkedField",
          "name": "channels",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "channel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "optedOut",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NotificationKind",
  "abstractKey": null
};
})();

(node as any).hash = "cf38f1fdaf13ca9ae1d59f0069a9d66a";

export default node;
