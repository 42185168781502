import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { LabelData, useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ProfileAvatarSize } from "@/user/common/avatar/ProfileAvatar"
import UserAvatar, { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import {
  DEFAULT_USER_AVATAR_SIZE,
  DEFAULT_USER_AVATAR_STACK_SIZE,
} from "@/user/common/avatar/util/userAvatarConstants"
import ProfileListModal from "@/user/common/profile-list-modal/ProfileListModal"
import { getUserFullName } from "@/user/util/userUtils"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import styleIf from "@assets/style/util/styleIf"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import { DiscoIcon, DiscoText, DiscoTooltipProps, GroovyTextColorKind } from "@disco-ui"
import DiscoButtonsGroup from "@disco-ui/button/DiscoButtonsGroup"
import { useTheme } from "@material-ui/core"
import { ArrayUtils, range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { MouseEventHandler, useState } from "react"

export type CountVariants = "always" | "hidden" | "if-more" | "placeholder" | "never"

export interface UserAvatarStackProps extends TestIDProps {
  users: UserAvatarShape[]
  stackSize?: number
  className?: string
  totalUsers?: number
  avatarSize?: ProfileAvatarSize
  disableHover?: boolean
  onClick?: MouseEventHandler
  viewUserTooltip?: Pick<DiscoTooltipProps, "content" | "dataPrivate">
  onClickAddUser?: MouseEventHandler
  addUserTooltip?: Pick<DiscoTooltipProps, "content">
  /** @example "5 members" */
  totalUsersCopy?: string
  countVariant?: CountVariants
  disableDefaultListModal?: boolean
  customUsersLabel?: Omit<LabelData, "kind" | "description">
  textVariant?: TextVariantWithModifiers
  textColor?: GroovyTextColorKind
  singleUserCopy?: string
  unstyledButton?: boolean
}

function UserAvatarStack({
  testid = "UserAvatarStack",
  className,
  users,
  stackSize = DEFAULT_USER_AVATAR_STACK_SIZE,
  avatarSize = DEFAULT_USER_AVATAR_SIZE,
  totalUsers: providedTotalUsers,
  totalUsersCopy,
  disableHover = false,
  onClick,
  viewUserTooltip,
  onClickAddUser,
  addUserTooltip,
  /** how should the user count be displayed? By default the count will display if there are more totalUsers than the number visible in stack */
  countVariant = "if-more",
  disableDefaultListModal = false,
  customUsersLabel,
  textVariant = avatarSize > 32 ? "body-sm-500" : "body-xs-500",
  textColor = "text.secondary",
  singleUserCopy,
  unstyledButton: propsUnstyledButton,
}: UserAvatarStackProps) {
  const activeProduct = useActiveProduct()
  const membersLabel = useLabel(activeProduct ? "product_member" : "organization_member")
  const usersLabel = customUsersLabel || membersLabel
  const [isModalOpen, setIsModalOpen] = useState(false)

  const totalUsers = providedTotalUsers || users.length
  const visibleUsers = users.slice(0, stackSize)
  const hasMoreUsers = visibleUsers.length < totalUsers
  const shouldShowCount =
    countVariant === "always" || (countVariant !== "never" && hasMoreUsers)

  const showViewUsersButton = users.length > 0 || countVariant === "always"
  const showAddUsersButton = !!onClickAddUser

  // If we are not rendering the 'AddUser' button and there's either one avatar or we're showing the single circle placeholder count
  const unstyledButton = isUnstyledButton()

  // never show the fallback list modal if an onclick behaviour is provided
  const listModalDisabled = !!onClick || disableDefaultListModal

  const classes = useStyles({ avatarSize, disableHover, stackSize })
  const theme = useTheme()

  if (users.length === 0 && !onClickAddUser && countVariant !== "always") {
    return null
  }

  return (
    <>
      <DiscoButtonsGroup
        testid={`${testid}.buttons-group`}
        color={unstyledButton ? "transparent" : "grey"}
        variant={unstyledButton ? "text" : "outlined"}
        noPadding={unstyledButton}
        buttons={[
          ...ArrayUtils.spreadIf(
            {
              onClick: handleOnClick,
              uniqueKey: "AvatarStack",
              testid: `${testid}.button.avatar-stack`,
              TooltipProps: {
                content: viewUserTooltip?.content || renderUsersTooltip(),
                disableHoverListener: disableHover,
                dataPrivate: true,
              },
              content: renderAvatars(),
              customClass: classes.button,
            },
            showViewUsersButton
          ),
          ...ArrayUtils.spreadIf(
            {
              onClick: onClickAddUser!,
              uniqueKey: "AddUser",
              testid: `${testid}.button.add-user`,
              TooltipProps: {
                content: addUserTooltip?.content || `Add ${usersLabel.plural}`,
                disableHoverListener: disableHover,
              },
              content: <DiscoIcon icon={"user-add"} color={"inherit"} />,
              customClass: classes.button,
            },
            showAddUsersButton
          ),
        ]}
      />

      {!listModalDisabled && (
        // Default to the ProfileListModal
        <ProfileListModal
          isOpen={isModalOpen}
          onClose={closeModal}
          users={users}
          totalUsers={totalUsers}
        />
      )}
    </>
  )

  function handleOnClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    event.stopPropagation()

    if (onClick) {
      onClick(event)
    } else if (!listModalDisabled) {
      openModal()
    }
  }
  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

  function renderAvatars() {
    if (countVariant === "placeholder")
      return (
        <AvatarPlaceholder
          testid={`${testid}.count-placeholder`}
          backgroundColor={
            theme.palette.type === "light"
              ? theme.palette.groovy.neutral[300]
              : theme.palette.groovy.onDark[700]
          }
          color={theme.palette.text.primary}
          size={avatarSize}
          text={totalUsers}
        />
      )
    return (
      <>
        {Boolean(visibleUsers.length) && (
          <ul className={classNames(classes.avatarList, className)}>
            {visibleUsers.map((user) => (
              <li key={user.first_name} className={classes.avatarListItem}>
                <UserAvatar
                  testid={`UserAvatarStack.user.${user.first_name}`}
                  user={user}
                  size={avatarSize}
                />
              </li>
            ))}
          </ul>
        )}

        {singleUserCopy && visibleUsers.length === 1 && (
          <DiscoText
            variant={textVariant}
            setColor={"inherit"}
            color={textColor}
            paddingLeft={1}
            noWrap
          >
            {singleUserCopy}
          </DiscoText>
        )}

        {shouldShowCount && (
          <DiscoText
            testid={`${testid}.count`}
            variant={textVariant}
            color={textColor}
            paddingLeft={totalUsers ? 1 : 0}
            noWrap
          >
            {countVariant === "hidden"
              ? `+`
              : totalUsersCopy ||
                (totalUsers === 0 ? `0 ${usersLabel.plural}` : totalUsers)}
          </DiscoText>
        )}
      </>
    )
  }

  function renderUsersTooltip() {
    return visibleUsers.reduce((copy, user, i) => {
      const isOnly = visibleUsers.length === 1
      const hasTwo = visibleUsers.length === 2
      const isFirst = i === 0
      const isSecond = i === 1
      const isLast = i === visibleUsers.length - 1
      const userFullName = getUserFullName(user)
      return `${copy}${
        isOnly || isFirst
          ? " "
          : hasTwo && isSecond
          ? " and "
          : isLast && !hasMoreUsers
          ? ", and "
          : ", "
      }${userFullName}${
        isLast && hasMoreUsers ? `, and ${totalUsers - visibleUsers.length} more.` : ""
      }`
    }, `View ${usersLabel.plural}${users.length > 0 ? " including" : ""}`)
  }

  function isUnstyledButton(): boolean {
    if (propsUnstyledButton) return true
    if (users.length === 1 && singleUserCopy) return false
    return (
      !showAddUsersButton &&
      ((users.length === 1 && countVariant !== "always") ||
        countVariant === "placeholder" ||
        disableHover)
    )
  }
}

interface StyleProps {
  avatarSize: number
  stackSize?: number
  disableHover: boolean
}

const useStyles = makeUseStyles((theme) => ({
  avatarList: ({ stackSize = 2 }: StyleProps) => ({
    display: "flex",
    listStyleType: "none",
    alignItems: "center",
    ...styleIf(stackSize > 1, {
      marginRight: theme.spacing(1.25),
      marginLeft: theme.spacing(0.25),
    }),
  }),
  avatarListItem: ({ avatarSize, stackSize = 2 }: StyleProps) => ({
    /** Overlap 8px */
    ...styleIf(stackSize > 1, {
      maxWidth: `calc(${avatarSize}px - 8px)`,
    }),
    height: avatarSize,
    overflow: "visible",
  }),
  userAvatar: ({ avatarSize }: StyleProps) => ({
    width: avatarSize,
    height: avatarSize,
  }),
  button: {
    borderRadius: theme.measure.borderRadius.xl,
  },
}))

export const UserAvatarStackSkeleton: React.FC<{
  className?: string
  stackSize?: number
  avatarSize?: number
  disableHover?: boolean
}> = ({
  className,
  stackSize = DEFAULT_USER_AVATAR_STACK_SIZE,
  avatarSize = DEFAULT_USER_AVATAR_SIZE,
  disableHover = false,
}) => {
  const classes = useStyles({ avatarSize, disableHover })
  const theme = useTheme()

  return (
    <ul className={classNames(classes.avatarList, className)}>
      {range(stackSize).map((i) => (
        <li key={i} className={classes.avatarListItem}>
          <div
            className={classes.userAvatar}
            style={{ background: theme.palette.groovy.neutral[100], borderRadius: "50%" }}
          />
        </li>
      ))}
    </ul>
  )
}

export default UserAvatarStack
